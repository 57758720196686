<template>
  <div>
    <div class="setting-rule-main">
      <div class="setting-rule-info-left">
        <a-menu
          style="
            height: 670px;
            overflow: auto;
            overflow-x: hidden;
            width: 200px;
          "
          :open-keys.sync="openKeys"
          mode="inline"
          @click="handleClick"
        >
          <a-sub-menu v-for="item in menu" :key="item.key">
            <span slot="title"> {{ item.title }}</span>
            <a-menu-item v-for="i in item.children" :key="i.key">
              {{ i.title }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
      <div class="setting-rule-info-right">
        <a-tabs tab-position="left" :activeKey="activeKey">
          <a-tab-pane key="initial" tab=""
            ><div
              style="
                display: flex;
                height: 670px;
                align-items: center;
                justify-content: center;
              "
            >
              <h1 style="opacity: 0.4">请选择权限模块</h1>
            </div></a-tab-pane
          >
          <a-tab-pane v-for="item in content" :key="item.key" :tab="item.key">
            <div style="height: 670px; overflow: auto">
              <a-tree
                checkable
                v-model="checkedKeys"
                :checkStrictly="true"
                :treeData="item.tree"
                :defaultExpandAll="true"
                :selectable="false"
                @check="onCheck"
              />
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ruleSelectTree from "./ruleSelectTree";
import { getAuthInfo, updateAuth } from "@/api/auth";

export default {
  name: "ruleEdit",
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      current: ["mail"],
      openKeys: ["0", "1"],
      checkedKeys: {
        checked: [],
      },
      checkedBefore: {
        checked: [],
      },
      activeKey: "initial",
      group1: {
        checkAll: false,
        indeterminate: false,
        children: {
          cbox1: false,
          cbox2: false,
          cbox3: false,
        },
      },
      menu: [
        {
          title: "前台管理系统",
          key: "0",
          children: [
            {
              title: "登录分享起源",
              key: "0-0",
            },
            {
              title: "首页",
              key: "0-1",
            },
            {
              title: "搜索",
              key: "0-2",
            },
            {
              title: "公司详情",
              key: "0-3",
            },
            {
              title: "项目发现",
              key: "0-4",
            },
            {
              title: "项目管理",
              key: "0-5",
            },
            {
              title: "基金管理",
              key: "0-6",
            },
            {
              title: "系统管理",
              key: "0-7",
            },
            {
              title: "文件管理",
              key: "0-8",
            },
            {
              title: "起源实验室",
              key: "0-9",
            },
            {
              title: "更多",
              key: "0-10",
            },
          ],
        },
        {
          title: "后台管理系统",
          key: "1",
          children: [
            {
              title: "登录后台管理",
              key: "1-0",
            },
            {
              title: "查询列表",
              key: "1-1",
            },
            {
              title: "评价体系",
              key: "1-2",
            },
            {
              title: "文件管理",
              key: "1-3",
            },
            {
              title: "用户中心",
              key: "1-4",
            },
            {
              title: "系统管理",
              key: "1-5",
            },
          ],
        },
      ],
      content: ruleSelectTree,
      routers: [],
      actions: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getAuthInfo({ name: this.record.roleName }).then((res) => {
        if (res.code === 0) {
          this.checkedKeys.checked =
            res.data.key !== null ? res.data.key.split(",") : [];
        }
      });
    },
    //遍历树
    traverseTree(arr) {
      this.routers = [];
      this.actions = [];
      arr.forEach((item) => {
        item.tree.forEach((i) => {
          this.traverse(i);
        });
      });
    },
    traverse(obj) {
      if (obj.children) {
        obj.children.forEach((item) => {
          this.traverse(item);
        });
      }
      if (this.checkedKeys.checked.includes(obj.key)) {
        if (obj.router) {
          this.routers.push(obj.router);
        }
        if (obj.action) {
          this.actions.push(obj.action);
        }
      }
    },
    handleClick(e) {
      // console.log("click", e);
      this.activeKey = e.key;
    },
    onCheck(checkedKeys) {
      //判断是勾选checkbox还是取消勾选
      if (this.checkedBefore.checked.length > checkedKeys.checked.length) {
        //取消勾选
        //父无子必无
        let discheckedKey = "";
        let currentChecked = [];
        this.checkedBefore.checked.forEach((item) => {
          if (!checkedKeys.checked.includes(item)) {
            discheckedKey = item;
          }
        });
        checkedKeys.checked.forEach((item) => {
          if (item.substr(0, discheckedKey.length) !== discheckedKey) {
            currentChecked.push(item);
          }
        });
        checkedKeys.checked = currentChecked;
      } else {
        //勾选
        //子选父必选
        checkedKeys.checked.forEach((item) => {
          let key = item;
          for (let i = key.length; i >= 5; i -= 2) {
            let fatherKey = key.substr(0, i);
            if (!checkedKeys.checked.includes(fatherKey)) {
              checkedKeys.checked.push(fatherKey);
            }
          }
        });
      }
      this.checkedBefore = JSON.parse(JSON.stringify(checkedKeys)); //储存上一次的checkedKeys列表
      this.traverseTree(ruleSelectTree);
    },
    onOk() {
      this.traverseTree(ruleSelectTree);
      const routers = Array.from(new Set(this.routers));
      const actions = Array.from(new Set(this.actions));
      const roleAuth = {
        roleId: this.record.roleId,
        route: routers.join(","),
        action: actions.join(","),
        key: this.checkedKeys.checked.join(","),
      };
      updateAuth(roleAuth).then((res) => {
        if (res.code === 0) {
          this.$message.success("更新成功");
        }
      });
      return true;
    },
  },
};
</script>

<style scoped>
.setting-rule-main {
  display: flex;
}
.setting-rule-info-right >>> .ant-tabs-nav {
  display: none;
}
.setting-rule-info-right >>> .ant-tabs-left-content {
  border-left: none;
}
.setting-rule-info-right {
  flex: 1;
}
.setting-rule-info-right >>> .ant-tree li ul {
  padding-left: 30px;
}
</style>